import React from 'react';
import './image.styles.scss'
import ReactTooltip from "react-tooltip";

export const Image = (image) => {
    return(
        <div>
            <div className="details-picture">
                <div data-tip={image.image.Help} className="pictures">
                    <div className="image-gallery " aria-live="polite">
                        <div className="image-gallery-content">
                            <div className="image-gallery-slide-wrapper bottom ">
                                <div className="image-gallery-slides">
                                    <div className="image-gallery-slide center" role="button"
                                        style={{transform: 'translate3d(0%, 0px, 0px)', transition: 'all 300ms ease-out 0s'}}>
                                        <div className="pictures-picture">
                                            <div className="picture" style={{backgroundImage : 'url(data:image/png;base64,' + image.image.imageValue + ')' }}>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p>{image.image.Label}</p>
                <ReactTooltip multiline={true} effect="solid" place="right" type="info" backgroundColor="#a8005c"/>
            </div>
        </div>
    )
}

