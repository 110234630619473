import React from 'react';
import './header.styles.scss';

export const Header = ({menus, setUidJson }) => (
    <div className="navigation">
        <div className="cyan-stripe navigation-shadow">
            <div className="container">
                <nav className="navbar navbar-default">
                    <div className="container">
                        <div className="row">
                            <div className="navigation-header">
                                <div className="navigation-toggle">
                                    <button type="button" className="navbar-toggle collapsed">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>
                                <div className="navigation-title">
                                    <a href="">
                                        <span className="hidden-xs hidden-sm">Configuration</span>
                                        <span className="hidden-md hidden-lg">Configuration</span>
                                    </a>
                                </div>
                                <div className="navigation-logo"/>
                                </div>
                            </div>
                            <div className="navbar-collapse collapse">
                                <div className="row">
                                    <ul id="app-navigation" className="nav navbar-nav">
                                        {
                                            menus.map(menu => 
                                                (
                                                    <li role="presentation" key={menu.NodePath}>
                                                        <a role="button" onClick={() => setUidJson(menu)} >{menu.Label}</a>
                                                    </li>
                                                )
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav> 
                </div>
            </div>
        <div className="navigation-separator"></div>
    </div>
)