import React from 'react';

import './text.styles.scss'

export const Text = ({text}) => (

    <div>
        <p> {text} </p>
    </div>

)