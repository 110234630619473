import React, {useState, useRef, useEffect} from 'react';
import './parameter-enum.styles.scss'
import ReactTooltip from "react-tooltip";

export const Enum = ({enumeration}) => {

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [opened, setOpened] = useState(0);

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpened(false)
            }
          }
      
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }

    return (
    <div className="form-group">
        <form>
            <div className="row">
                <div className="col-md-8" data-tip={enumeration.Help} >
                    <label> {enumeration.Label} </label>
                    <div ref={wrapperRef} className="select" role="button" onClick={() => setOpened(!opened)}>
                        <div className="select-arrow" role="button">
                            <span className="icon-eh-show-more"></span>
                        </div>
                        <input className="form-control select-input" type="text"/>
                        <div className="select-option-selected" role="button">
                            <div>
                                <span className="select-option-value">{enumeration.UITemplate.Enumeration[0].Label}</span>
                            </div>
                        </div>
                        { 
                            opened === true && 
                            <div className="select-options">
                            {
                                enumeration.UITemplate.Enumeration.map(item => 
                                    <div key={item.Label} className="select-option">{item.Label}</div>
                                )
                            }  
                            </div> 
                        }
                    </div>
                </div>
                <ReactTooltip multiline={true} effect="solid" place="right" type="info" backgroundColor="#a8005c"/>
            </div>
        </form>
    </div>)
}