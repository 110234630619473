import React from 'react';

import './plugin.styles.scss'

export const Plugin = () => (

    <div>
        Plugin
    </div>

)