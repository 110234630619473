import React from 'react';
import './menu-page.styles.scss'
import { MenuContent } from '../../../content/menu-content/menu-content.component';
import { MenuHeader } from '../menu-header/menu-header.component';

export const MenuPage = ({menu}) => (

    <div>
        <MenuHeader name={menu.Label}/>
        <MenuContent menu={menu}/>
    </div>
)