import React, {Component} from 'react';
import './parameterization.styles.scss';
import { RootMenuHeader } from '../../edd-elements/layout/menu/root-menu-header/root-menu-header.component'
import { Ascii } from '../../edd-elements/content/parameter/ascii/parameter-ascii.component'
import { PackedAscii } from '../../edd-elements/content/parameter/packed-ascii/parameter-packed-ascii.component'
import { Float } from '../../edd-elements/content/parameter/float/parameter-float.component'
import { Int } from '../../edd-elements/content/parameter/int/parameter-int.component'
import { Enum } from '../../edd-elements/content/parameter/enum/parameter-enum.component'
import { BitEnum } from '../../edd-elements/content/parameter/bit-enum/parameter-bit-enum.component'
import { MenuReference } from '../../edd-elements/layout/menu/menu-reference/menu-reference.component';
import { Image } from '../../edd-elements/content/image/image.component';
import { RowBreak } from '../../edd-elements/layout/row-break/row-break.component';
import { ColumnBreak} from '../../edd-elements/layout/column-break/column-break.component';
import { Plugin } from '../../edd-elements/content/plugin/plugin.component';
import { Action } from '../../edd-elements/content/action/action.component';
import { Text } from '../../edd-elements/content/text/text.component';
import { Chart } from '../../edd-elements/content/chart/chart.component';
import { Graph } from '../../edd-elements/content/graph/graph.component';
import { MenuContent } from '../../edd-elements/content/menu-content/menu-content.component';


class Parameterization extends Component {

    render()
    {
        const {menu, setUidJson} = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <RootMenuHeader name={menu.Label}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <MenuContent menu={menu} setUidJson={setUidJson} />
                    </div>
                </div> 
            </div>
        );
    }
};
export default Parameterization;