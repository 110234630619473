import React from 'react';

import './root-menu-header.styles.scss'

export const RootMenuHeader = ({name}) => (

    <div id="menu-header" className="actionbar-wrapper">
        <div id="instrumentation-edit-header" className="heading">
            <h1>{name}</h1>
        </div>
    </div>

)

