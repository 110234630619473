import React from 'react';

import './grid.styles.scss'

export const Grid = ({grid}) => (

    <div>
        Grid
    </div>

)