import React from 'react';

import './menu-reference.styles.scss'

export const MenuReference = ({menu, setUidJson}) => (

    <div className="">
        <button className="btn btn-primary" onClick={() => setUidJson(menu)}>{menu.Label}</button>
    </div>

)