import React, { Component } from 'react';
import './main-content.styles.scss';
import { Navigation } from '../navigation/navigation-wrapper/navigation-wrapper.component';
import Parameterization from '../parameterization/parameterization.component';

class MainContent extends Component {
    render()
    {
        const {deviceConnected, menu, setUidJson} = this.props;

        if(deviceConnected){
            return(
                <span>
                    <div className="cyan-stripe content-wrapper">
                        <div className="container">
                        </div>
                        <div id="root-content">
                            {/*<Navigation menuContent={menuContent}/>*/}
                            <Parameterization menu={menu} setUidJson={setUidJson} />
                        </div>
                    </div>
                </span>
            );
        }else{
            return(
                <span>
                    <div className="cyan-stripe content-wrapper">
                        <div className="container">
                        </div>
                        <div id="root-content">
                            <div className="container"> 
                                <p>Connecting to device...</p>
                            </div>
                        </div>
                    </div>
                </span>
            );
        }
    }
};
export default MainContent;