import React from 'react';
import './parameter-ascii.styles.scss'
import ReactTooltip from "react-tooltip";

export const Ascii = ({parameter}) => (

    <div className="form-group">
        <form>
            <div className="row">
                <div data-tip={parameter.Help} className="col-md-8">
                    <div className="form-group">
                        <label > {parameter.Label}</label>
                        <input className="form-control undefined" type="text" readOnly={parameter.Handling === 'ro' ? true : false}/>
                        {/* <span id="unit">{parameter.Unit}</span> */}
                    </div>
                </div>
                <ReactTooltip multiline={true} effect="solid" place="right" type="info" backgroundColor="#a8005c"/>
            </div>
        </form>
    </div>
)