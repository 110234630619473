import React from 'react';

import './action.styles.scss'

export const Action = ({action}) => (

    <div>
        <button className="btn btn-secondary">{action.Label}</button>
    </div>

)