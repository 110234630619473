
import React from 'react';

import './menu-content.styles.scss'

import { MenuHeader } from '../../layout/menu/root-menu-header/root-menu-header.component'
import { Ascii } from '../parameter/ascii/parameter-ascii.component'
import { PackedAscii } from '../parameter/packed-ascii/parameter-packed-ascii.component'
import { Float } from '../parameter/float/parameter-float.component'
import { Int } from '../parameter/int/parameter-int.component'
import { Enum } from '../parameter/enum/parameter-enum.component'
import { BitEnum } from '../parameter/bit-enum/parameter-bit-enum.component'
import { MenuReference } from '../../layout/menu/menu-reference/menu-reference.component';
import { MenuPage } from '../../layout/menu/menu-page/menu-page.component';
import { MenuGroup } from '../../layout/menu/menu-group/menu-group.component';
import { MenuTable } from '../../layout/menu/menu-table/menu-table.component';
import { MenuDialog } from '../../layout/menu/menu-dialog/menu-dialog.component';
import { Image } from '../image/image.component';
import { RowBreak } from '../../layout/row-break/row-break.component';
import { ColumnBreak} from '../../layout/column-break/column-break.component';
import { Plugin } from '../plugin/plugin.component';
import { Action } from '../action/action.component';
import { Text } from '../text/text.component';
import { Chart } from '../chart/chart.component';
import { Graph } from '../graph/graph.component';
import { Grid } from '../grid/grid.component';


const getMenuContent = (menu, setUidJson) => (
    menu.ItemList.map((item, index) => {
        if(item.ItemType === 'Menu')
        {
            if(item.Style === 'Page')
            {
                return <MenuPage menu={item} key={item.NodePath} />;
            }
            else if(item.Style === 'Group')
            {
                return <MenuGroup menu={item} key={item.NodePath} />;
            }else if(item.Style === 'Table')
            {
                return <MenuTable menu={item} key={item.NodePath} />;
            }
            else if(item.Style === 'Window' || item.Style === 'Dialog') 
            {
                return <MenuReference menu={item} key={item.NodePath} setUidJson={setUidJson} />;
            }
        }else if (item.ItemType === 'Parameter')
        {
            if(item.UITemplate.Enumeration)
            {
                return <Enum enumeration={item} key={item.NodePath} />;
            }else if(item.UITemplate.BitEnumeration)
            {
                return <BitEnum bitEnumeration={item} key={item.NodePath} />;
            }
            return <Ascii parameter={item} key={item.NodePath} />;
        }else if (item.ItemType === 'Action')
        {
            return <Action action={item} key={item.NodePath} />;
        }else if (item.ItemType === 'Image')
        {
            return <Image image={item} key={item.NodePath} />;
        }else if (item.ItemType === 'Chart')
        {
            return <Chart chart={item} key={item.NodePath} />;
        }else if (item.ItemType === 'Graph')
        {
            return <Graph graph={item} key={item.NodePath} />;
        }else if (item.ItemType === 'Grid')
        {
            return <Grid grid={item} key={item.NodePath} />;
        }
        else if (item.ItemType === 'ColumnBreak' || item.ItemType === 'Separator')
        {
            return <ColumnBreak key={item.NodePath} />;
        }else if (item.ItemType === 'RowBreak')
        {
            return <RowBreak key={item.NodePath} />;
        }else if (item.ItemType === 'Plugin')
        {
            return <Plugin plugin={item} key={item.NodePath} />;
        }else if (item.ItemType === 'Text')
        {
            return <Text text={item} key={item.NodePath} />;
        }
        return null;
    })
)

export const MenuContent = ({menu, setUidJson}) => (

    <div id="menu-content">
        {getMenuContent(menu, setUidJson)}
    </div>
)