import React from 'react';

import './parameter-bit-enum.styles.scss'

export const BitEnum = ({bitEnumeration}) => (

    // <div className="permission-group-items">
        <div className="permission-group-item">
            <div className="permission-assignee">
                <div>{bitEnumeration.Label}</div>
            </div>
            <div className="permission-items">
                {
                    bitEnumeration.UITemplate.BitEnumeration.map(enumItem => (
                        <div className="permission-item" key={enumItem.Label}>
                            <input id={enumItem.Label} type="checkbox" className="checked" disabled={bitEnumeration.Handling === 'ro' ? true : false}/>
                            <label htmlFor={enumItem.Label}>{enumItem.Label}</label>
                        </div>
                    ))
                }
            </div>
        </div>
    // </div>
)