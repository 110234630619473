import React from 'react';

import './menu-group.styles.scss'
import { MenuContent } from '../../../content/menu-content/menu-content.component';
import { MenuHeader } from '../menu-header/menu-header.component';

export const MenuGroup = ({menu}) => (

    <div>
        <MenuHeader name={menu.Label}/>
        <MenuContent menu={menu}/>
    </div>

)