import React from 'react';

import './graph.styles.scss'

export const Graph = () => (

    <div>
        Graph
    </div>

)