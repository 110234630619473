import React from 'react';

import './chart.styles.scss'

export const Chart = () => (

    <div>
        Chart
    </div>

)